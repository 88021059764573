@use 'sass:math';

p-inputmask{
    &.ng-touched {
      &.ng-dirty {
        &.ng-invalid {
          .p-inputmask, .p-inputtext{
              @include invalid-input();
          }
        }
      }
   
      &.ng-pristine {
        &.ng-invalid {
          .p-inputmask, .p-inputtext{
              @include invalid-input();
          }
        }
      }
    }
}

p-inputmask.p-inputmask-clearable {
    .p-inputtext {
        padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
    }
    
    .p-inputmask-clear-icon {
        color: $inputIconColor;
        right: nth($inputPadding, 2);
    }
}