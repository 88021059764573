p-inputnumber{
    &.ng-touched {
      &.ng-dirty {
        &.ng-invalid {
          .p-inputnumber, .p-inputtext{
              @include invalid-input();
          }
        }
      }
   
      &.ng-pristine {
        &.ng-invalid {
          .p-inputnumber, .p-inputtext{
              @include invalid-input();
          }
        }
      }
    }
}

p-inputnumber.p-inputnumber-clearable {
    .p-inputnumber-input {
        padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
    }
    
    .p-inputnumber-clear-icon {
        color: $inputIconColor;
        right: nth($inputPadding, 2);
    }
}

p-inputnumber.p-inputnumber-clearable {
    .p-inputnumber-buttons-stacked{
        .p-inputnumber-clear-icon {
           right: $buttonIconOnlyWidth + nth($inputPadding, 2);
        }
    }
    
    .p-inputnumber-buttons-horizontal{
        .p-inputnumber-clear-icon {
           right: $buttonIconOnlyWidth + nth($inputPadding, 2);
        }
    }
}

p-inputnumber {
  .p-inputnumber {
    .p-inputnumber-input {
      background-color: transparent !important; /* eslint-disable-line */
      box-shadow: 0 0 0px 1000px white inset !important; /* eslint-disable-line */
    }
  }
}
