
@import '../../../assets/themes/mi-theme/_variables.scss';// Layout
.left-1px {
  left: 1px;
}

.left-20 {
  left: 20px;
}

.left-22 {
  left: 22px;
}

.left-5 {
  left: 5px;
}

.left-55 {
  left: 55%;
}

.left-40 {
  left: 40px;
}

.left-6 {
  left: 6px;
}

.left-23 {
  left: 23px;
}

.left-62 {
  left: 62%;
}

.left-79 {
  left: 79%;
}

.left-160 {
  left: 160px;
}

.left-7 {
  left: 7px;
}

.left-80px{
  left: 80px;
}

.right-m-5px {
  right: -5px;
}

.right-m-10px {
  right: -10px;
}

.right-7 {
  right: 7px;
}

.right-15px {
  right: 15px;
}

.right-9 {
  right: 9px;
}

.right-20 {
  right: 20px;
}

.right-25 {
  right: 25px;
}

.right-30 {
  right: 30px;
}

.right-10 {
  right: 10px;
}

.right-2 {
  right: 2px;
}

.bottom-5 {
  bottom: 5px;
}

.bottom-8 {
  bottom: 8px;
}
.left-46 {
  left: 46px;
}
.left-10 {
  left: 10px;
}
.bottom-41 {
  bottom: 41px;
}
.bottom-m-15 {
  bottom: -15px;
}

.bottom-m-28 {
  bottom: -28px;
}

.left-81 {
  left: 81px;
}

.bottom-18 {
  bottom: 18px;
}

.bottom-14 {
  bottom: 14px;
}

.bottom-10 {
  bottom: 10px;
}

.top-m-14 {
  top: -14px;
}

.top-m23px {
  top: -23px;
}

.top-19 {
  top: 19px;
}

.top-16 {
  top: 16px;
}

.top-80px {
  top: 80px;
}

.left-12 {
  left: 12px;
}

.top-2 {
  top: 2px;
}

.top-24 {
  top: 24px;
}

.top-28 {
  top: 28px;
}

.top-7 {
  top: 7px;
}

.top-4 {
  top: 4px;
}

.top-5 {
  top: 5px;
}

.top-15 {
  top: 15px;
}

.top-30 {
  top: 30px;
}

.top-10 {
  top: 10px;
}

.top-18 {
  top: 18px;
}

.top-25 {
  top: 25%;
}

.top-26 {
  top: 26px;
}

.left-32 {
  left: 32%;
}

.left-37 {
  left: 37%;
}

.left-30 {
  left: 30px;
}

.left-325 {
  left: 325px;
}

.right-m-35 {
  right: -35px;
}

.right-8 {
  right: 8px;
}

.right-40 {
  right: 40px;
}

.right-205 {
  right: 205px;
}

.right-115 {
  right: 115px;
}

.left-3 {
  left: 3px;
}

.left-36 {
  left: 36px;
}

.bottom-20 {
  bottom: 20px;
}
.bottom-24 {
  bottom: 24px;
}

.bottom-3 {
  bottom: 3px;
}

.top-1 {
  top: 1px;
}
.top-3 {
  top: 3px;
}

.right-5 {
  right: 5px;
}

.right-15 {
  right: 15px;
}

.bottom-2 {
  bottom: 2px;
}

.bottom-4 {
  bottom: 4px;
}

.bottom-13px {
  bottom: 13px;
}

.top-45mpx {
  top: -45px;
}

//Size

.wh-70 {
  width: 70px;
  height: 70px;
}
.width-18 {
  width: 18px;
}

.width-97 {
  width: 97%;
}

.width-100px {
  width: 100px;
}

.wh-11 {
  width: 11px;
  height: 11px;
}

.wh-10 {
  width: 10px;
  height: 10px;
}

.wh-130 {
  width: 130px;
  height: 130px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .wh-template-preview {
    height: 24px;
    width: 32%;
  }

  .whm-template-preview {
    height: 24px;
    width: 32%;
    // margin: 0 3px;
  }

  .wh-label-preview {
    height: 48.6px;
    width: auto;
  }

  .wh-template-div {
    width: auto;
    height: 269px;
  }

  .wh-label-div {
    width: auto;
    height: 99px;
  }

  .wh-radio-card {
    height: auto;
    width: auto;
  }

  .wh-template-label-div {
    height: 344px;
    width: auto;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1900px) {
  .wh-template-preview {
    height: 24px;
    width: 32%;
  }

  .whm-template-preview {
    height: 24px;
    width: 32%;
    // margin: 0 3px;
  }

  .wh-label-preview {
    height: 48.6px;
    width: 100px;
  }

  .wh-template-div {
    width: 192px;
    height: 269px;
  }

  .wh-label-div {
    width: 160px;
    height: 99px;
    margin: -7px 0 0 14px;
  }

  .wh-radio-card {
    height: auto;
    width: 246px;
  }

  .wh-template-label-div {
    height: 344px;
    width: 414px;
  }
}

@media screen and (min-width: 1920px) {
  .wh-template-preview {
    height: 24px;
    width: 32%;
  }

  .whm-template-preview {
    height: 24px;
    width: 32%;
    // margin: 0 3px;
  }

  .wh-label-preview {
    height: 48.6px;
    width: 81px;
  }

  .wh-template-div {
    width: 192px;
    height: 269px;
  }

  .wh-label-div {
    width: 160px;
    height: 99px;
    margin: -7px 0 0 14px;
  }

  .wh-radio-card {
    height: auto;
    width: 246px;
  }

  .wh-template-label-div {
    height: 344px;
    width: 414px;
  }
}

.width-110 {
  width: 110%;
}

.width-170 {
  width: 170px;
}

.height-22 {
  height: 22px;
}

.height-44 {
  height: 44px;
}

.height-35 {
  height: 35px;
}

.height-45 {
  height: 45px;
}

.width-45 {
  width: 45px;
}

.height-15 {
  height: 15px;
}
.width-15 {
  width: 15px;
}
.height-26 {
  height: 26px;
}

.width-26 {
  width: 26px;
}

.height-37 {
  height: 37.11px;
}

.width-37 {
  width: 37.11px;
}

.height-90 {
  height: 90%;
}

.height-91 {
  height: 91%;
}

.width-96 {
  width: 96%;
}

.height-53 {
  height: 53px;
}

.width-53 {
  width: 53px;
}

.height-40-px {
  height: 40px;
}

.height-17px {
  height: 17px;
}

.height-160 {
  height: 160px;
}

.height-225px {
  height: 225px;
}

.height-467px {
  height: 467px;
}

.width-40-px {
  width: 40px;
}

.width-40 {
  width: 40%;
}

.width-132 {
  width: 132px;
}

.width-200 {
  width: 200px;
}

.width-70 {
  width: 70px;
}

.width-90 {
  width: 90px;
}

.width-46 {
  width: 46px;
}

.width-48 {
  width: 48px;
}

.width-150px {
  width: 150px;
}

.max-w-40 {
  max-width: 40%;
}

.width-568 {
  width: 568px;
}

.height-calc {
  height: calc(100vh - 200px);
}

.height-85 {
  height: 85%;
}

.height-32 {
  height: 32%;
}

.height-65px {
  height: 65px;
}

.height-34px {
  height: 34px;
}

.search-icon {
  top: 0;
}

.breadcrumb-text a {
  margin-left: 5px;
}

// color
.bg-transparent {
  background-color: transparent;
}
.border-color-red {
  border-color: #FF1010;
}
.border-offwhite {
  border-color: #f5f6fa;
}
.bg-f5f5f5 {
  background-color: #F5F5F5;
}
.default-text-color-gray {
  color: #F5F5F5;
}
.default-text-color-red {
  color: #FF1010;
}
.bg-cyan-msg {
  background-color: #6c9bc7;
}
.bg-default-text-blue {
  background-color: #1e90ff;
}
.bg-default-text-black {
  background-color: #262c2d;
}
.bg-green {
  background-color: #008000;
}
.bg-default-e00 {
  background-color: #e0e0e0;
}
.bg-redirect-icon {
  background-color: #2e3a59;
}
.bg-route-icon {
  background-color: #a2abae;
}
.bg-cancel-icon {
  background-color: #b22222;
}
.bg-color-gray {
  background: #f8f9fa;
}
.default-text-blue-color {
  color: $primaryColor;
}
.text-green-color {
  color: #008000;
}
.text-yellow-color {
  color: #ffa500;
}
.text-red-color {
  color: #FF0000;
}
.text-dark-red-color {
  color: #b22222;
}
.required-field {
  color: #F40025;
}
.default-text-black-color {
  color: #262c2d;
}

.default-border {
  border: solid 1px #262c2d;
}

.default-text-light-black-color {
  color: #555555;
}

.default-text-light-gray {
  color: #a2abae;
}
.default-separator-color {
color: #e0e0e0;
}
.default-print-label-color {
  color: #777777;
}
.bg-print-label-color {
  background-color: #777777;
}

// margin & padding
.px-10px {
  padding-left: 10px;
  padding-right: 10px;
}

.pr-16px {
  padding-right: 16px
}

.pl-37 {
  padding-left: 37px;
}

.pl-7px {
  padding-left: 7px;
}

.p-10-20 {
  padding: 10px 20px;
}
.p-98px {
  padding: 9px 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-28px {
  margin-left: 28px;
}

.mt-1px {
  margin-top: 1px;
}

.mt-100px {
  margin-top: 160px;
}

.ml-4px {
  margin-left: 4px;
}

.mb-2px {
  margin-bottom: 2px;
}

.mb-6px {
  margin-bottom: 6px;
}
.mb-8px {
  margin-bottom: 8px;
}
.mb-13 {
  margin-bottom: 13px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-17 {
  margin-right: 17px;
}

.pr-14 {
  padding-right: 14px;
}
.pt-14 {
  padding-top: 14px;
}
.pt-16 {
  padding-top: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pb-10 {
  padding-bottom: 10px;
}
.py-10 {
  padding: 10px 0px;
}
.pt-10 {
  padding-top: 10px;
}
.padding-10 {
  padding: 10px;
}

.padding-16 {
  padding: 16px;
}
.m-10 {
  margin: 10px;
}

.mx-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.mr-16px {
  margin-right: 16px;
}

// font

.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}

.font-10 {
  font-size: 10px;
}

//positions
.float-r {
  float: right;
}

.float-l {
  float: left;
}

.hide-scrollbar {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: 8px; /* Firefox 64 */
}
/* scrollbar */
::-webkit-scrollbar {
  width: 7px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: transparent;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.loader-page-center-overlay {
  position: absolute;
  background: transparent;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  z-index: 1;

  .spin-div {
    position: absolute;
    top: 48%;
    left: 48%;
  }
}

.p-tabview {
  .p-tabview-panels {
    padding: 0;
  }

  .p-tabview-nav li .p-tabview-nav-link {
    padding: 20px 0 19px 0;
    margin: 0 20px -2px 16px;
    
  }
}

p-breadcrumb {
  .p-breadcrumb {
    ol {
      li {
        chevronrighticon {
          display: none;
        }
      }
    }
  }
}

.breadcrumb-child {
  .p-menuitem-link {
    .p-menuitem-text {
      font-size: 14px;
      font-weight: 300;
    }
  }
}

.forward-slash::before {
  content: "\002F";
  font-size: 19px;
  color: #777777;
  font-weight: 300;
}

calendaricon {
  color: #777777;
}

.p-button {
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.border-bottom-settings {
  border-bottom: 1px solid #EEEEEE;
}

.more-horizontal-icon {
  border: 1.5px solid #e0e0e0;
  border-radius: 6px;
}
.nav-item-border {
  border: 1px solid #c0c0c0;
  border-radius: 6px;
}

form {
  p-calendar {
    span {
      input {
        border-right: 0;
      }
    }
  }
}

.bulk-update-icon {
  float: right;
  right: 0;
  position: absolute;
}

.bulk-update-list {
  margin: 4px;

  .p-menuitem-content {
    background: #FFF;

    &:hover {
      border-radius: 5px;
    }
  }
}

.per-page-limit {
  span {
    span {
      button {
        angleupicon {
          top: 58px;
          position: relative;
        }

        angledownicon {
          top: 6px;
          position: relative;
        }
      }
    }
  }
}

.menu-width {
  width: 253px;
}

.width-30px {
  width: 30px;
}

.width-20 {
  width: 20%;
}

.custom-field-menu-width {
  left: 395px !important; /* eslint-disable-line */
}

html,body,label,div,button,p,span {
  user-select: none;
}

.tab-icons {
  display: flex;
  align-items: center;

  .mi-lg {
      // padding: 7px 9px;
      margin-right: 5px;
      width: 16px;
      height: 16px;
      display: inline-block;
      padding: 0;
  }
}

.mi-add {
  background: $primaryColor;
  margin-right: 5px;
  width: 16px;
  height: 16px;
  display: inline-block;
  padding: 0;
}

.add-more {
  cursor: pointer;
  color: $primaryColor;
  display: flex;
  align-items: center;
}

body:has(.off-duty-reason) {
  .p-component-overlay {
    &.p-blockui-document {
      display: none !important; /* eslint-disable-line */
    }
  }
}