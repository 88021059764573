@import '../../../../app/modules/shared/components/sidebar/sidebar.component.scss';

.signin-box-container {
  background: #F6F6F6;
  display: table;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  .signin-box-inner-container {
    display: table-cell;
    vertical-align: middle;

    .company-logo {
      padding: 13px;
      text-align: center;

      img {
        width: 119px;
      }
    }

    .signin-box {
      margin: 0 auto;
      position: relative;
      width:50%;
    }
  }
}

@media screen and (max-width: 40em) {
  .signin-box-container {
    .signin-box-inner-container {
      .signin-box {
        left: 8.335%;
      }
    }
  }
}
