@use 'sass:math';

.p-overlaypanel {
    background: $overlayContentBg;
    color: $panelContentTextColor;
    border: $overlayContentBorder;
    border-radius: $borderRadius;
    box-shadow: $overlayContainerShadow;
    width: 33rem;
    margin-top: 0px !important; /* eslint-disable-line */

    .p-overlaypanel-content {
        padding: 0.5rem 1rem;

        .calendar-view-overlay-btns {
            .delete-btn {
                .p-button {
                    border-radius: 6px;
                    background: #FFF;
                    border: 1px solid #F00;
                    height: 24px;
                    color: #F00;
                    width: 76px;
                    padding: 8px;

                    .p-button-label {
                        font-size: 13px;
                    }
                }
            }

            .edit {
                .p-button {
                    border-radius: 6px;
                    background: #FFF;
                    border: 1px solid #1E90FF;
                    height: 24px;
                    color: #1E90FF;
                    width: 64px;
                    padding: 8px;

                    .p-button-label {
                        font-size: 13px;
                    }
                }
            }

            .publish-btn {
                .p-button {
                    border-radius: 6px;
                    background: #FFF;
                    border: 1px solid #1E90FF;
                    height: 24px;
                    color: #1E90FF;
                    padding: 8px;
                    width: 101px;

                    .p-button-label {
                        font-size: 13px;
                    }
                }
            }

            .unpublish-btn {
                .p-button {
                    border-radius: 6px;
                    background: #1E90FF;
                    border: 1px solid #1E90FF;
                    height: 24px;
                    color: #FFF;
                    padding: 8px;
                    width: 83px;

                    .p-button-label {
                        font-size: 13px;
                    }
                }
            }
        }
    }

    .p-overlaypanel-close {
        background: #FFF;
        color: $overlayButtonTextColor;
        width: $actionIconWidth;
        height: $actionIconHeight;
        transition: $actionIconTransition;
        border-radius: $actionIconBorderRadius;
        position: absolute;
        top: 0.7rem;
        right: 1rem;

        &:enabled:hover {
            background: #F0F0F0;
            color: $overlayButtonTextColor;
        }
    }

    &:after {
        border: solid transparent;
        border-color: rgba($overlayContentBg, 0);
        border-bottom-color: $overlayContentBg;
    }

    &:before {
        border: solid transparent;

        @if (nth($overlayContentBorder, 2) == 'none') {
            border-color: rgba($overlayContentBg, 0);
            border-bottom-color: scale-color($overlayContentBg, $lightness: -5%);
        }
        @else {
            border-color: rgba(nth($overlayContentBorder, 3), 0);
            border-bottom-color: scale-color(nth($overlayContentBorder, 3), $lightness: -5%);
        }
    }

    &.p-overlaypanel-flipped {
        &:after {
            border-top-color: $overlayContentBg;
        }

        &:before {
            @if (nth($overlayContentBorder, 2) == 'none') {
                border-top-color: $overlayContentBg;
            }
            @else {
                border-top-color: nth($overlayContentBorder, 3);
            }
        }
    }
    p-inputnumber {
        .p-inputnumber {
            button.p-button-icon-only {
                width: 2rem;
                height: 18px;
            }
        }

        .p-inputnumber-buttons-stacked .p-inputnumber-input {
            border-top-right-radius:5px !important; /* eslint-disable-line */
            border-bottom-right-radius:5px !important; /* eslint-disable-line */
            width: 100%;

            &:hover {
                background: #F0F0F0;
            }
        }

        .p-inputnumber-buttons-stacked .p-inputnumber-button-group {
            display: flex;
            position: absolute;
            flex-direction: column;
            right: 0;
            bottom: .3px;
            border-radius: 5px;
            top:0;
        }

        .p-inputnumber-buttons-stacked .p-inputnumber-button-group .p-button.p-inputnumber-button {
            background: transparent;
            color: #999999;
            border: 0;

            .pi-angle-up {
                top: 67px;
                position: relative;
            }

            .pi-angle-down {
                top: 10px;
                position: relative;
            }
        }
    }
}
