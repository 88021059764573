
$gutter: 1rem;
html,body,p {    
    outline: none;    
}
@import "../node_modules/primeng/resources/primeng.min.css";
@import "./assets/layout/layout.scss";
@import './assets/themes/mi-theme/theme.scss';
@import "../node_modules/primeflex/primeflex.css";
@import '../node_modules/primeicons/primeicons.css';

@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";


@import 'shared/components/header.component';
@import 'shared/components/page-header.component';
@import 'shared/directives/search-location.directive';
@import 'app/pages/sign-in.component';
@import '../src/assets/themes/mi-theme/common_primeflex.scss';



