* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: $scale;
    cursor: default;
}

body {
    font-family: var(--font-family);
    color: var(--text-color);
    background-color: var(--surface-ground);
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    // color: var(--primary-color);
}

.layout-wrapper {
    min-height: 100vh;
}
