.p-menu {
    padding: $verticalMenuPadding;
    background: $menuBg;
    color: $menuTextColor;
    border: $menuBorder;
    border-radius: $borderRadius;
    width: 16rem;

    .p-menuitem {
        @include menuitem();
    }

    &.p-menu-overlay {
        background: $overlayMenuBg;
        border: $overlayMenuBorder;
        box-shadow: $overlayMenuShadow;
        min-width: 100px;
    }

    .p-submenu-header {
        margin: $submenuHeaderMargin;
        padding: $submenuHeaderPadding;
        color: $submenuHeaderTextColor;
        background: $submenuHeaderBg;
        font-weight: $submenuHeaderFontWeight;
        border-top-right-radius: $submenuHeaderBorderRadius;
        border-top-left-radius: $submenuHeaderBorderRadius;
    }

    .p-menuitem-separator {
        border-top: $divider;
        margin: $menuSeparatorMargin;
    }

    .p-menu-separator {
        border-top: $divider;
        margin: $menuSeparatorMargin;
    }

    .p-hidden {
        display: none;
    }

    .p-hidden {
        display: block;
    }

    .p-menuitem-badge {
        @include menuitem-badge();
    }

    .p-menuitem-link {
        padding: 0.75rem 1.25rem;
        color: #495057;
        border-radius: 0;
        transition: box-shadow 0.2s;
        user-select: none;
    }

    .p-menuitem-link:not(.p-disabled):hover {
        background: #e9ecef;
    }
}
